import { type IFetchError } from "ofetch"

const order: Ref<SpreeOrder | null> = ref(null)
const loading = ref(false)
const errors = ref<JsonApiError[]>([])

const useOrder = () => {
  const $router = useRouter()

  const sanitizeOrder = (err: IFetchError) => {
    if (err.status === 409) {
      errors.value = err.data.errors
      $router.push("/checkout/address")
    }
  }

  return {
    sanitizeOrder,
    errors,
    loading,
    order,
  }
}

export default useOrder
